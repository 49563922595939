@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&display=swap');

body,
html {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Montserrat', sans-serif;
    background-color: #dfdfdf !important;
}

/* datatables */
@import url('../node_modules/datatables.net-bs4/css/dataTables.bootstrap4.min.css');
@import url('../node_modules/datatables.net-dt/css/dataTables.dataTables.min.css');
@import url('../node_modules/datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css');
@import url('../node_modules/datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css');


/* width */

::-webkit-scrollbar {
    width: 10px;
}


/* Track */

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 50px;
}


/* Handle */

::-webkit-scrollbar-thumb {
    background: rgb(111, 168, 130);
    border-radius: 10px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

p {
    font-size: 15px !important;
}

.table-data {
    overflow-x: auto;
}

html {
    overflow-x: hidden;
}

h1 {
    font-size: 20px !important;
}

.navbar {
    background-color: rgb(142, 223, 142) !important;
    border-radius: 0px !important;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    z-index: 1;
}

.nav-link {
    color: white !important;
}

.navbar-brand {
    color: white !important;
}

.navbar-nav {
    margin-left: auto !important;
}

.btn {
    /* background-color: green !important; */
    color: white !important;
    box-shadow: none !important;
}

.bg-green {
    background-color: green !important;
}

.container {
    padding: 0 1em;
}

.section-label {
    display: inline-block;
    font-weight: bold;
    padding: 0 !important;
    margin: 0 !important;
    color: chartreuse !important;
}

.nin-form-group select {
    padding: 5px 6px 4px !important;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100% !important;
    display: block !important;
    margin-top: 10px;
    border: 3px solid aliceblue !important;
    height: 37px !important;
    border-radius: 30px !important;
    box-shadow: 10px 10px 5px -10px #aaaaaa !important;
}

.bs {
    margin-top: 20px !important;
    border-radius: 5px !important;
    padding: 20px !important;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.card {
    box-shadow: 10px 10px 5px -10px #aaaaaa !important;
    max-width: 500px !important;
    margin: 0 auto !important;
    margin-top: 20px !important;
}

select {
    width: 100% !important;
    display: block !important;
    margin-top: 10px;
    border: 3px solid aliceblue !important;
    height: 37px !important;
}

select {
    border-radius: 30px !important;
    box-shadow: 10px 10px 5px -10px #aaaaaa !important;
}

.loadMore {
    background-color: rgb(18, 133, 33);
    border: none;
    color: rgb(253, 253, 253);
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 10px;
    cursor: pointer;
    border-radius: 16px;
    box-shadow: 10px 10px 5px -10px #aaaaaa !important;
}

.mailChimp input[type=email] {
    width: 100% !important;
    /* display: block !important; */
    border: 3px solid aliceblue !important;
    height: 50px !important;
    padding: 20px;
    /* margin: 10px;s */
    box-shadow: 10px 10px 5px -10px #aaaaaa !important;
    border-radius: 30px !important;
}

.mailChimp button {
    background-color: rgb(18, 133, 33);
    border: none;
    color: rgb(253, 253, 253);
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 10px;
    cursor: pointer;
    border-radius: 16px;
    box-shadow: 10px 10px 5px -10px #aaaaaa !important;
}
.swal2-popup.swal2-toast .swal2-title {
    margin: .5em 1em;
    padding: 0;
    font-size: 12px !important;
    text-align: initial;
}

.swal2-select {
    display: none !important;
    display: unset;
    border: unset;
    height: unset !important;
    box-shadow: unset;
    border-radius: unset !important;
    padding: unset !important;
    width: unset;
}

.swal2-loader {
    border-color: #52c41a rgba(0, 0, 0, 0) #28a745 rgba(0, 0, 0, 0) !important;
}

.swal-button{
    background-color: #52c41a !important;
}

.userScreenInputs {
    border-radius: 30px !important;
    box-shadow: 10px 10px 5px -10px #aaaaaa !important;
}

.userScreenTitle {
    margin-top: -50px !important;
}

h2 {
    font-size: 30px !important;
    padding: 10px;
    font-weight: bold !important;
    text-align: center;
}

.ant-picker-range {
    width: 100%;
}

.wrapper {
    height: 100%;
}

.top-header {
    min-height: 70px;
    /* margin-bottom: 150px; */
    background-color: green !important;
    border-radius: 0 0 50px 50px !important;
    background-image: url("https://images.unsplash.com/photo-1487017159836-4e23ece2e4cf?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=871&q=80");
    background-blend-mode: screen !important;
    background-position: 100% !important;
    background-size: cover !important;
}

.content-body {
    margin-top: -150px !important;
}

.ant-card {
    border-radius: 15px !important;
    margin-bottom: 50px !important;
}

.adminText {
    font-size: 30px !important;
    font-weight: 900 !important;
    /* stroke: black !important; */
    /* -webkit-text-stroke: 2px #191a18; */
}

td {
    max-width: 20px !important;
    overflow-wrap: break-word !important;
}

.rounded {
    border-radius: 30px !important;
}

.loader {
    margin: 0 auto !important;
    display: flex;
    display: flex;
    justify-content: center;

}

@media (min-width: 1025px) {
    .h-custom {
        height: 100vh !important;
    }
}

.card-registration .select-input.form-control[readonly]:not([disabled]) {
    font-size: 1rem;
    line-height: 2.15;
    padding-left: .75em;
    padding-right: .75em;
}

.card-registration .select-arrow {
    top: 13px;
}

.gradient-custom-2 {
    /* fallback for old browsers */
    background: #a1fdd7;
    /* Chrome 10-25, Safari 5.1-6 */
    background: -webkit-linear-gradient(to right, rgba(161, 196, 253, 1), rgba(194, 233, 251, 1));
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background: linear-gradient(to right, rgb(248, 248, 248), rgb(240, 241, 241));
}

.bg-indigo {
    background: linear-gradient(to right, rgb(4, 43, 19), rgb(1, 58, 34));
}

@media (min-width: 992px) {
    .card-registration-2 .bg-indigo {
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
    }
}

@media (max-width: 991px) {
    .card-registration-2 .bg-indigo {
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
    }
}

.next-button1 {
    margin-left: 50px !important;
}

.PhoneInputInput {
    border: none !important;
}

.active-user {
    background-color: rgb(218 218 218 / 10%) !important;
}

.featured {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-left: 3px !important;
}

.featuredItem {
    flex: 1;
    margin: 0px 20px;
    padding: 30px;
    border-radius: 10px;
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    background-color: #ffffff;
    margin-bottom: 30px;
    margin-top: 20px;
}

.featuredTitle {
    font-size: 20px;
}

.featuredMoneyContainer {
    margin: 10px 0px;
    display: flex;
    align-items: center;
}

.featuredMoney {
    font-size: 30px;
    font-weight: 600;
}

.featuredMoneyRate {
    display: flex;
    align-items: center;
    margin-left: 20px;
}

.featuredIcon {
    font-size: 14px;
    margin-left: 5px;
    color: green;
}

.featuredIcon.negative {
    color: red;
}

.featuredSub {
    font-size: 15px;
    color: gray;
}

.chartContainer {
    overflow-x: auto;
    width: 100%;
    padding: 30px;
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    background-color: #ffffff;
}

.vertical-center {
    /* position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1; */
    margin-top: 30px;
}

.footer {
    position: relative;
    bottom: 0;
    width: 100%;
}


/* CSS variables. */

:root {
    --PhoneInput-color--focus: #03b2cb;
    --PhoneInputInternationalIconPhone-opacity: 0.8;
    --PhoneInputInternationalIconGlobe-opacity: 0.65;
    --PhoneInputCountrySelect-marginRight: 0.35em;
    --PhoneInputCountrySelectArrow-width: 0.3em;
    --PhoneInputCountrySelectArrow-marginLeft: var(--PhoneInputCountrySelect-marginRight);
    --PhoneInputCountrySelectArrow-borderWidth: 1px;
    --PhoneInputCountrySelectArrow-opacity: 0.45;
    --PhoneInputCountrySelectArrow-color: inherit;
    --PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus);
    --PhoneInputCountrySelectArrow-transform: rotate(45deg);
    --PhoneInputCountryFlag-aspectRatio: 1.5;
    --PhoneInputCountryFlag-height: 1em;
    --PhoneInputCountryFlag-borderWidth: 1px;
    --PhoneInputCountryFlag-borderColor: rgba(0, 0, 0, 0.5);
    --PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);
    --PhoneInputCountryFlag-backgroundColor--loading: rgba(0, 0, 0, 0.1);
}

.PhoneInput {
    /* This is done to stretch the contents of this component. */
    display: flex;
    align-items: center;
}

.PhoneInputInput {
    /* The phone number input stretches to fill all empty space */
    flex: 1;
    /* The phone number input should shrink
	   to make room for the extension input */
    min-width: 0;
}

.PhoneInputCountryIcon {
    width: calc(var(--PhoneInputCountryFlag-height) * var(--PhoneInputCountryFlag-aspectRatio));
    height: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--square {
    width: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--border {
    /* Removed `background-color` because when an `<img/>` was still loading
	   it would show a dark gray rectangle. */
    /* For some reason the `<img/>` is not stretched to 100% width and height
	   and sometime there can be seen white pixels of the background at top and bottom. */
    background-color: var(--PhoneInputCountryFlag-backgroundColor--loading);
    /* Border is added via `box-shadow` because `border` interferes with `width`/`height`. */
    /* For some reason the `<img/>` is not stretched to 100% width and height
	   and sometime there can be seen white pixels of the background at top and bottom,
	   so an additional "inset" border is added. */
    box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor), inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor);
}

.PhoneInputCountryIconImg {
    /* Fixes weird vertical space above the flag icon. */
    /* https://gitlab.com/catamphetamine/react-phone-number-input/-/issues/7#note_348586559 */
    display: block;
    /* 3rd party <SVG/> flag icons won't stretch if they have `width` and `height`.
	   Also, if an <SVG/> icon's aspect ratio was different, it wouldn't fit too. */
    width: 100%;
    height: 100%;
}

.PhoneInputInternationalIconPhone {
    opacity: var(--PhoneInputInternationalIconPhone-opacity);
}

.PhoneInputInternationalIconGlobe {
    opacity: var(--PhoneInputInternationalIconGlobe-opacity);
}


/* Styling native country `<select/>`. */

.PhoneInputCountry {
    position: relative;
    align-self: stretch;
    display: flex;
    align-items: center;
    margin-right: var(--PhoneInputCountrySelect-marginRight);
}

.PhoneInputCountrySelect {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    border: 0;
    opacity: 0;
    cursor: pointer;
}

.PhoneInputCountrySelect[disabled] {
    cursor: default;
}

.PhoneInputCountrySelectArrow {
    display: block;
    content: '';
    width: var(--PhoneInputCountrySelectArrow-width);
    height: var(--PhoneInputCountrySelectArrow-width);
    margin-left: var(--PhoneInputCountrySelectArrow-marginLeft);
    border-style: solid;
    border-color: var(--PhoneInputCountrySelectArrow-color);
    border-top-width: 0;
    border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
    border-left-width: 0;
    border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
    transform: var(--PhoneInputCountrySelectArrow-transform);
    opacity: var(--PhoneInputCountrySelectArrow-opacity);
}

.PhoneInputCountrySelect:focus+.PhoneInputCountryIcon+.PhoneInputCountrySelectArrow {
    opacity: 1;
    color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.PhoneInputCountrySelect:focus+.PhoneInputCountryIcon--border {
    box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus), inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) var(--PhoneInputCountryFlag-borderColor--focus);
}

.PhoneInputCountrySelect:focus+.PhoneInputCountryIcon .PhoneInputInternationalIconGlobe {
    opacity: 1;
    color: var(--PhoneInputCountrySelectArrow-color--focus);
}

.ant-checkbox-disabled .ant-checkbox-inner {
    background-color: #28a745 !important;
}

.ant-checkbox-disabled+span {
    color: #dc3545 !important;
}

/* flutterwave button */
.flutterwave-button button {
    background-color: green;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
}

/* datatable */
.pagination .page-item .page-link {
    color: #198754;
    /* Bootstrap success color */
}

.pagination .page-item .page-link.active {
    background-color: #198754;
    /* Bootstrap success background color */
    border-color: #198754;
    /* Bootstrap success border color */
    color: white;
}

.pagination .page-item .page-link:hover {
    background-color: #759e87;
    /* Darker shade of success color for hover effect */
    border-color: #145a32;
    color: white;
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #145a32;
    border-color: #145a32;
}


div.dt-container div.dt-length select {
    width: auto !important;
    display: inline-block !important;
    margin-right: .5em !important;
}

.full-width-select{
 /* Use !important here */
  border-radius: 0 !important; /* Use !important here */
  width: 100% !important;
  margin-top: 0px !important
    /* height: 100% !important; */
}

/* Add this CSS to your styles */
.form-select.is-valid {
    border-color: #28a745 !important; /* Green border */
  }
  
  .form-select.is-invalid {
    border-color: #dc3545 !important; /* Red border */
  }
  
  .form-select.is-valid:focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25) !important; /* Green shadow */
  }
  
  .form-select.is-invalid:focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25) !important; /* Red shadow */
  }

  /* Add this to your CSS file */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7); /* Dark background with opacity */
    backdrop-filter: blur(5px); /* Background blur effect */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Ensure it appears above other content */
  }
  
  .loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }